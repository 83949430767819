export default function mergeRecursive(obj1, obj2) {
  for (var p in obj2) {
    // Property in destination object set; update its value.
    if (obj2[p].constructor == Object) {
      obj1[p] = mergeRecursive(obj1[p], obj2[p]);
    } else {
      obj1[p] = obj2[p];
    }
  }

  return obj1;
}
