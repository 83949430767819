import Settings from '@hc-frontend/shared-assets/src/js/utils/settings';
import mergeRecursive from '../core/utils/mergeRecursive';

export const HEALTH = 'HEALTH';
export const PLACEMENT_LANDING = 'LANDING';
export const PLACEMENT_PAGE = 'PAGE';
export const PLACEMENT_SALES = 'PIVOT_LANDING_FBM';

export const defaultDomainConfigs = {
  product: HEALTH,
  session: {
    channel: 'ORGANIC',
    src: 'ORGANIC',
    domain: 'HC.COM',
  },
  phone: {
    site: 'www.healthcare.com',
    call_type: 'bundle',
  },
};

export const getEnv = () => {
  return Settings.isProd ? 'prod' : Settings.isStg ? 'stg' : 'qa';
};

export const getPlacement = (
  path = window.location.pathname,
  domain = window.location.hostname,
) => {
  if (domain.includes('sales.') && domain.includes('pivothealth.')) {
    return PLACEMENT_SALES;
  } else {
    return /^\/$/.test(path) ? PLACEMENT_LANDING : PLACEMENT_PAGE;
  }
};
export const cleanDomainName = (hostName = window.location.hostname) =>
  hostName.replace(/(stg|qa|local)\./, '');

export const getDomainConfigs = () => {
  return window.domainConfigs && typeof window.domainConfigs === 'object'
    ? mergeRecursive(defaultDomainConfigs, window.domainConfigs)
    : defaultDomainConfigs;
};
