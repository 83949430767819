/* istanbul ignore file */
import getPhoneNumberV5 from '@hc-frontend/shared-assets/src/js/sem/flow-content/getPhoneNumberV5';
import waitForElement from '@hc-frontend/shared-assets/src/js/utils/dom/waitForElement';

import {
  PLACEMENT_SALES,
  getPlacement,
  getDomainConfigs,
} from '../../environment';

const responseOnError = {
  phone: {
    promo_number: '',
    promo_number_formatted: '',
    schedule: {},
    tty_formatted: '',
  },
};

export default function getPhoneNumber(hcStorage) {
  const configs = getDomainConfigs();
  var curr_site = configs.phone.site;
  if (getPlacement() == PLACEMENT_SALES) {
    curr_site = 'sales.pivothealth.com';
  }
  return getPhoneNumberV5({
    call_type: configs.phone.call_type,
    device: hcStorage.Device,
    placement: getPlacement(),
    site: curr_site,
    src: hcStorage.source,
    product: configs.product.toLowerCase(),
  })
    .then(data => {
      const response = data || responseOnError;
      const phone = response.phone;

      return {
        number: phone.promo_number,
        numberFormatted: phone.promo_number_formatted,
      };
    })
    .then(({ number, numberFormatted }) => {
      waitForElement('.promo_number', 3000, 300, function (els) {
        els.forEach(el => {
          el.href = `tel:${number}`;
        });
      });
      waitForElement('.promo_number_formatted', 3000, 300, function (els) {
        els.forEach(el => {
          el.innerText = numberFormatted;
        });
      });
    });
}
