/* istanbul ignore file */
import {getHCStorage, setHCStorage} from '../../storage/hcStorage';
/**
 * Execute external function when react finish render
 */
function executeWhenFinishToRender() {
  const functions = window.executeWhenFinishToRender || [];
  if (Array.isArray(functions)) {
    window.executeWhenFinishToRender = {
      push: fun => {
        try {
          const newHC = fun(getHCStorage());
          if (typeof newHC === 'object') {
            setHCStorage(newHC);
          }
        } catch (error) {
          console.warn(error); // eslint-disable-line
        }
      },
    };

    functions.forEach(func => window.executeWhenFinishToRender.push(func));
  }
}

export default executeWhenFinishToRender;
