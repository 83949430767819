import initRaygun from '@hc-frontend/shared-assets/src/js/utils/raygun';
import initSnowplow from './snowplow';
import createSession from '../core/session/createSession';
import getPhoneNumber from '../core/services/phone/getPhoneNumber';
import executeWhenFinishToRender from '../core/utils/DOM/executeWhenFinishToRender';
import { cleanDomainName, getEnv, getPlacement } from '../core/environment';
import { saveCheckPoint } from '../core/services/saveCheckpoint';

const tags = [
  `env:${getEnv()}`,
  'company:hc',
  `service:content-site`,
  `page:${getPlacement()}`,
  `site:${cleanDomainName()}`,
];

initRaygun(rg4js => {
  rg4js('withTags', tags);
  createSession()
    .then(getPhoneNumber)
    .then(executeWhenFinishToRender)
    .then(saveCheckPoint);
});

initSnowplow();
