/* istanbul ignore file */
import Settings from '@hc-frontend/shared-assets/src/js/utils/settings';

const subDomain = Settings.subDomain;
const subDomainWithQA = Settings.subDomainWithQA;

export const FEATURE_ADS = `https://searchads${subDomain}.healthcare.com/api/searchads`;
export const ROTATION_TOOL = `https://rotation-tool-srv${subDomain}.healthcare.com/feed/search`;
export const PLAN_SRV = `https://plans-srv${subDomain}.healthcare.com`;
export const API_URL = `https://api${subDomainWithQA}.healthcare.com`;
export const PLAN_SRV_HEALTH = `${PLAN_SRV}/RecommendSEM`;
export const PLAN_SRV_MEDICARE = `${PLAN_SRV}/MedigapSEM`;
export const CHECK_SUBSIDY = `${PLAN_SRV}/SubsidyCheck`;
