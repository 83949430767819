/* istanbul ignore file */
import loadData from '@hc-frontend/shared-assets/src/js/sem/storage-manager/loadData';
import urlParamsToObject from '@hc-frontend/shared-assets/src/js/utils/url/urlParamsToObject';
import {getDomainConfigs} from '../environment';
const payloadReducer = payload => {
  const configs = getDomainConfigs();
  const urlParams = urlParamsToObject();
  payload.Label.Source = document.referrer;
  payload.Label.Channel = configs.session.channel;
  payload.Label.Product = configs.product;
  payload.Label.Src = urlParams.src || urlParams.SRC || configs.session.src;
  payload.Label.Domain = configs.session.domain;
  return payload;
};

export default function createSession() {
  return loadData(null, payloadReducer);
}
