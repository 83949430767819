import {
  hcSessionContextProvider,
  initSnowplowTracker,
  localStorageHcSessionDataProvider,
  localStorageAnonymizedPiiDataProvider,
  anonymizedPiiContextProvider,
  trackUserClick,
} from '@hc-frontend/third-party-snowplow';
import memoize from 'nano-memoize';

export const CATEGORY_LANDING = 'landing';
export const ACTION_CLICK = 'click';
export const ACTION_SUBMIT = 'submit';

/**
 * @public
 * Initializes Snowplow based on hostname.
 *
 */

function getEnvironment(hostname) {
  if (
    hostname.includes('dev.') ||
    hostname.includes('stg.') ||
    hostname.includes('qa.') ||
    hostname.includes('local.')
  ) {
    return false;
  }
  return true;
}

function initSptracker() {
  const hostname = window.location.hostname;
  const environment = getEnvironment(hostname);

  return initSnowplowTracker({
    isProd: environment,
    contexts: [
      hcSessionContextProvider(localStorageHcSessionDataProvider),
      anonymizedPiiContextProvider(localStorageAnonymizedPiiDataProvider),
    ],
  });
}

export function getSnowplowDuid() {
  const { tracker } = spTracker();
  const domainUserInfo = tracker.getDomainUserInfo();

  const domainSessionId = domainUserInfo[6] || '';

  const domainUserId = domainUserInfo[1] || '';

  return {
    domainSessionId,
    domainUserId,
  };
}

export default function init() {
  const { pageView, tracker } = spTracker();

  pageView();
  window.snowplow = {
    trackClick: trackUserClick,
    getDomainUserInfo: tracker.getDomainUserInfo,
  };
}

const spTracker = memoize(initSptracker);
