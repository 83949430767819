/**
 * Send lead information to checkpoint endpoint to save lead data
 * in lead storage.
 *
 * @param {String} journeyId current journey id.
 * @param {String} plateId current plate id.
 * @param {Object} answers key-value pairs of questions and answers answered in funnel.
 * @param {String | undefined} productToOverwrite product
 * @returns {Promise<Object>}
 */

import { getSnowplowDuid } from '../../../apps/snowplow';
import { API_URL } from '../../endPoints';
import { getDomainConfigs } from '../../environment';
import { getHCStorage } from '../../storage/hcStorage';

export async function saveCheckPoint() {
  const hcStorage = getHCStorage();
  const configs = getDomainConfigs();

  const state = hcStorage;
  const userId = state.healthcareUserId;
  const sessionId = state.healthcareSession;
  const source = state.source;
  const product = configs.product;
  const landingUrl = state.landing_url === '' ? undefined : state.landing_url;
  const landingPage = `http://${getDomain()}/${source}`;
  const userAgent = navigator.userAgent;

  const { domainSessionId, domainUserId } = getSnowplowDuid();

  const requestBody = {
    user_id: userId,
    questions_answers: {},
    metadata: {
      src: source,
      product,
      landing_url: landingUrl,
      session_id: sessionId,
      domain_user_id: domainUserId,
      domain_session_id: domainSessionId,
      user_agent: userAgent,
      landing_page: landingPage,
    },
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: { 'Content-Type': 'application/json' },
  };

  return fetchWithRetry(
    `${API_URL}/reflexive-journey/api/v2/journeys/check-point`,
    options,
    1,
  );
}

/**
 * Wrapper around fetch to include retry functionality. Just
 * need a simple fetch as response from backend is always empty.
 *
 * @param {String} url url to make the request.
 * @param {Object} options object with fetch options.
 * @param {Number} retries number of times the fetch is needed to be executed.
 * @returns {Object}
 */

function fetchWithRetry(url, options, retries) {
  return fetch(url, options)
    .then(response => {
      if (response.ok) return {};
      throw new Error(response.status);
    })
    .catch(() => {
      if (retries > 0) {
        return fetchWithRetry(url, options, retries - 1);
      }
      return {};
    });
}

/**
 * @returns {String} Returns the domain value without subdomains
 */
function getDomain() {
  if (location.hostname) {
    const parts = location.hostname.replace(/^(www\.)/, '').split('.');
    while (parts.length > 2) {
      parts.shift();
    }
    const domain = parts.join('.');
    return `${domain.replace(/(^\.*)|(\.*$)/g, '')}`;
  }
  return '';
}
